<template>
  <div>
    <div class="row">
      <div class="col-9">
        <div class="card shadow-sm">
          <div class="card-body">
            <div class="card-title">
              <strong>Summary of Requests for Serial Number </strong>
            </div>

            <div class="d-flex justify-content-between">
              <div class="">
                <div class="form-inline">
                  <div class="form-group">
                    <label for="">No. of rows</label>
                    <select
                      name=""
                      id=""
                      class="form-control form-control-sm ml-2"
                      v-model="params.per_page"
                      @change="getSummaryOfRequests(DEFAULT_URL)"
                    >
                      <option value="10">10</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="">
                <div class="d-flex justify-content-right">
                  <div class="my-auto mr-3">
                    <span
                      >{{
                        `${pagination.from || 0}-${pagination.to ||
                          0} of ${pagination.total || 0}`
                      }}
                    </span>
                  </div>
                  <nav>
                    <ul class="pagination mb-0 pagination-sm">
                      <li
                        class="page-item"
                        :class="{ disabled: !pagination.prev_page_url }"
                      >
                        <a
                          class="page-link"
                          href="#"
                          @click.prevent="
                            getSummaryOfRequests(pagination.prev_page_url)
                          "
                        >
                          <span aria-hidden="true"
                            ><i class="bi bi-chevron-left"></i
                          ></span>
                          <span class="sr-only">Previous</span>
                        </a>
                      </li>

                      <li
                        class="page-item"
                        :class="{ disabled: !pagination.next_page_url }"
                      >
                        <a
                          class="page-link"
                          href="#"
                          @click.prevent="
                            getSummaryOfRequests(pagination.next_page_url)
                          "
                        >
                          <span aria-hidden="true"
                            ><i class="bi bi-chevron-right"></i
                          ></span>
                          <span class="sr-only">Next</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <table class="mt-3 table table-sm table-bordered">
              <thead>
                <th>HEI</th>
                <th>Component</th>
                <th>AY</th>
                <th>No. of Students</th>
                <th>Date Requested</th>
                <th>Issued on</th>
                <th>Duration (in days)</th>
              </thead>
              <tbody>
                <tr v-for="(request, index) in requests" :key="index">
                  <td>{{ request.abbrev }}</td>
                  <td>{{ request.component }}</td>
                  <td>{{ request.award_year }}</td>
                  <td>{{ request.num_of_students }}</td>
                  <td>{{ request.serial_date_requested | dateTime }}</td>
                  <td>{{ request.issued_at | dateTime }}</td>
                  <td>
                    {{ request.time_difference_days }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card shadow-sm">
          <div class="card-body">
            <div class="form-group">
              <label for="" class="font-weight-bold"
                >Cumulative Status Summary</label
              >
              <div class="input-group">
                <input
                  type="date"
                  class="form-control mr-2"
                  v-model="statusParams.from"
                />
                <span class="my-auto">to</span>
                <input
                  type="date"
                  class="form-control ml-2"
                  v-model="statusParams.to"
                />
              </div>
            </div>
            <div class="mb-3">
              <button class="btn btn-primary" @click="getStatusSummary">
                Go
              </button>
            </div>

            <ul class="list-group">
              <li
                class="list-group-item d-flex justify-content-between align-items-center"
              >
                PENDING
                <span class="badge badge-danger ">{{
                  stats['PENDING'].count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center"
              >
                ONGOING-NSTP1
                <span class="badge badge-primary ">{{
                  stats['ONGOING-NSTP1'].count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center"
              >
                ONGOING-NSTP2
                <span class="badge badge-primary ">{{
                  stats['ONGOING-NSTP2'].count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center"
              >
                COMPLETED
                <span class="badge badge-primary ">{{
                  stats['COMPLETED'].count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center"
              >
                REQUESTED FOR SERIAL
                <span class="badge badge-primary ">{{
                  stats['REQUESTED FOR SERIAL'].count
                }}</span>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center"
              >
                WITH SERIAL
                <span class="badge badge-success ">{{
                  stats['WITH SERIAL'].count
                }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Reports',
  props: {
    awardYears: {
      required: true,
      type: Array,
    },
    components: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      logs: [],
      statusParams: {
        from: '',
        to: '',
      },
      stats: {
        PENDING: { count: 1 },
        'ONGOING-NSTP1': { count: 3 },
        'ONGOING-NSTP2': { count: 4 },
        COMPLETED: { count: 5 },
        'REQUESTED FOR SERIAL': { count: 2 },
        'WITH SERIAL': { count: 1 },
      },
      params: {
        per_page: 10,
      },
      pagination: {},
      DEFAULT_URL: 'api/nstp/requests/summary',
      requests: [],
    };
  },
  methods: {
    makePagination(data) {
      let pagination = {
        current_page: data.current_page,
        last_page: data.last_page,
        next_page_url: data.next_page_url,
        prev_page_url: data.prev_page_url,
        from: data.from,
        to: data.to,
        total: data.total,
      };

      this.pagination = pagination;
    },
    /*  getNstpLogs() {
      axios.get('api/nstp/logs/all').then((res) => {
        this.logs = res.data;
      });
    }, */
    getStatusSummary() {
      this.transformValuesToZero();
      axios
        .get('api/nstp/status/summary', {
          params: this.statusParams,
        })
        .then((response) => {
          this.assignValues(response.data);
        })
        .catch(() => {});
    },
    assignValues(data) {
      for (const x of data) {
        const { count, status } = x;
        if (this.stats[status]) {
          this.stats[status].count = count;
        }
      }
    },
    transformValuesToZero() {
      Object.keys(this.stats).forEach((status) => {
        this.stats[status].count = 0;
      });
    },
    getSummaryOfRequests(page_url, config) {
      page_url = page_url || this.DEFAULT_URL;
      axios
        .get(page_url, {
          params: this.params,
        })
        .then((res) => {
          this.makePagination(res.data);
          this.requests = res.data.data;
          this.makePagination(res.data);
        });
    },
  },
  mounted() {
    // this.getNstpLogs();
    this.getStatusSummary();
    this.getSummaryOfRequests();
  },
};
</script>

<template>
  <div>
    <div class="card rounded shadow-sm">
      <div class="card-body">
        <form
          @submit.prevent="
            search(DEFAULT_URL, {
              searchLoader: { isEnabled: true },
              tableLoader: { isEnabled: false },
            })
          "
        >
          <div class="form-row">
            <div class="col-auto">
              <div class="form-group">
                <label for="">Last Name</label>
                <input
                  type="search"
                  class="form-control"
                  placeholder="Last Name"
                  v-model="params.last_name"
                />
              </div>
            </div>

            <div class="col-auto">
              <div class="form-group">
                <label for="">Component</label>
                <select
                  class="form-control col-auto"
                  v-model="params.component"
                >
                  <option selected value="">All</option>
                  <option v-for="component in components" :key="component">
                    {{ component }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-auto">
              <div class="form-group">
                <label for="">Academic Year</label>
                <select
                  class="form-control col-auto"
                  v-model="params.award_year"
                >
                  <option selected value="">All</option>
                  <option v-for="awardYear in awardYears" :key="awardYear">
                    {{ awardYear }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-auto">
              <div class="form-group">
                <label for="">Status</label>
                <select class="form-control col-auto" v-model="params.status">
                  <option value="">All</option>
                  <option
                    v-for="(status, index) in statuses"
                    :key="index"
                    :value="status"
                  >
                    {{ status }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-auto">
              <div class="form-group">
                <label for="">Sex</label>
                <select class="form-control col-auto" v-model="params.sex">
                  <option value="">All</option>
                  <option value="MALE">
                    MALE
                  </option>
                  <option value="FEMALE">FEMALE</option>
                </select>
              </div>
            </div>

            <div class="col-auto">
              <div class="form-group">
                <label for="">HEI</label>
                <select class="form-control col-auto" v-model="params.hei_code">
                  <option selected value="">All</option>
                  <option v-for="hei in heis" :key="hei.code" :value="hei.code">
                    {{ hei.abbrev }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-4">
              <div class="form-group">
                <label for="">Program</label>
                <select
                  class="form-control col-auto"
                  v-model="params.program_id"
                >
                  <option selected value="">All</option>
                  <option
                    v-for="program in $store.state.programs"
                    :key="program.id"
                    :value="program.id"
                  >
                    {{ program.program_name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-auto">
              <div class="form-group">
                <label for="">Date and Time Requested</label>
                <div class="input-group">
                  <input
                    type="datetime-local"
                    class="form-control mr-2"
                    v-model="params.dateRequestedFrom"
                  />
                  <span class="my-auto">to</span>
                  <input
                    type="datetime-local"
                    class="form-control ml-2"
                    v-model="params.dateRequestedTo"
                  />
                </div>
              </div>
            </div>

            <div class="col-auto">
              <div class="form-group">
                <label for="" class="invisible">Search Button</label>
                <button
                  type="submit"
                  class="btn btn-primary form-control"
                  style="background-color:#321fdb; color: #fff"
                  :disabled="searchLoader"
                >
                  <span v-if="!searchLoader">Search</span>
                  <span class="spinner-border spinner-border-sm" v-else></span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="card shadow-sm rounded">
      <div class="card-body">
        <div class="d-flex justify-content-between mt-2">
          <div class="form-inline">
            <div class="form-group">
              <label for="">No. of rows</label>
              <select
                name=""
                id=""
                class="form-control form-control-sm ml-2"
                v-model="params.per_page"
                @change="
                  search(DEFAULT_URL, {
                    searchLoader: { isEnabled: false },
                    tableLoader: { isEnabled: false },
                  })
                "
              >
                <option value="10">10</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </select>
            </div>
          </div>
          <div class="d-flex justify-content-right">
            <div class="my-auto mr-2">
              <span
                >{{
                  `${pagination.from || 0}-${pagination.to ||
                    0} of ${pagination.total || 0}`
                }}
              </span>
            </div>
            <nav>
              <ul class="pagination mb-0 pagination-sm">
                <li
                  class="page-item"
                  :class="{ disabled: !pagination.prev_page_url }"
                >
                  <a
                    class="page-link"
                    href="#"
                    @click.prevent="
                      search(pagination.prev_page_url, {
                        searchLoader: { isEnabled: false },
                        tableLoader: { isEnabled: false },
                      })
                    "
                  >
                    <span aria-hidden="true"
                      ><i class="bi bi-chevron-left"></i
                    ></span>
                    <span class="sr-only">Previous</span>
                  </a>
                </li>

                <li
                  class="page-item"
                  :class="{ disabled: !pagination.next_page_url }"
                >
                  <a
                    class="page-link"
                    href="#"
                    @click.prevent="
                      search(pagination.next_page_url, {
                        searchLoader: { isEnabled: false },
                        tableLoader: { isEnabled: false },
                      })
                    "
                  >
                    <span aria-hidden="true"
                      ><i class="bi bi-chevron-right"></i
                    ></span>
                    <span class="sr-only">Next</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="table-responsive">
          <table
            id="masterlist-table"
            class="table table-sm table-bordered table-hover mt-3"
          >
            <thead>
              <th>ID</th>
              <th>NAME</th>
              <th>HEI</th>
              <th>COMP.</th>
              <th>A.Y</th>
              <th>PROGRAM</th>
              <th>DATE ENCODED</th>
              <th>LAST UPDATED BY</th>
              <th>STATUS</th>
            </thead>
            <tbody>
              <tr class="text-center" v-if="!students.length && !tableLoader">
                <td colspan="11">
                  No results found.
                </td>
              </tr>
              <tr class="text-center" v-if="tableLoader">
                <td colspan="11">
                  <div class="spinner-border spinner-border-sm mr-1"></div>
                  Loading
                </td>
              </tr>
              <tr
                v-for="student in students"
                :key="student.id"
                @click="openProgressLogModal(student)"
              >
                <td>{{ student.id }}</td>

                <td>
                  {{
                    `${student.last_name}, ${student.first_name} ${student.middle_name} `
                  }}
                  <span class="form-text small text-muted mt-0">{{
                    student.serial_number
                  }}</span>
                </td>
                <td>{{ student.abbrev }}</td>
                <td>{{ student.component }}</td>
                <td>{{ student.award_year }}</td>
                <td class="text-uppercase">{{ student.program_name }}</td>

                <td class="text-uppercase">
                  {{ student.created_at | dateTime }}
                </td>
                <td>{{ student.updated_by }}</td>
                <td>
                  <span class="badge badge-secondary">
                    {{ student.status }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <ProgressLogTab ref="ProgressLogTab"></ProgressLogTab>
  </div>
</template>

<script>
import ProgressLogTab from './ProgressLogTab.vue';

export default {
  name: 'Masterlist',
  components: {
    ProgressLogTab,
  },
  props: {
    awardYears: {
      required: true,
      type: Array,
    },
    components: {
      required: true,
      type: Array,
    },
    statuses: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      students: [],
      tableLoader: false,
      pagination: {},
      heis: [],
      input: '',
      DEFAULT_URL: 'api/nstp/filter-students',
      awardYear: '',
      component: '',
      heiCode: '',
      status: '',
      searchLoader: true,
      params: {
        last_name: '',
        per_page: 10,
        award_year: '',
        component: '',
        hei_code: '',
        status: '',
        sex: '',
        program_id: '',
        dateRequestedFrom: '',
        dateRequestedTo: '',
      },
    };
  },
  methods: {
    openProgressLogModal(student) {
      this.$refs.ProgressLogTab.getStudentProgressLogs(student);
    },
    makePagination(data) {
      let pagination = {
        current_page: data.current_page,
        last_page: data.last_page,
        next_page_url: data.next_page_url,
        prev_page_url: data.prev_page_url,
        from: data.from,
        to: data.to,
        total: data.total,
      };

      this.pagination = pagination;
    },
    search(page_url, config) {
      this.tableLoader = config.tableLoader.isEnabled;
      this.searchLoader = config.searchLoader.isEnabled;

      axios
        .post(page_url, this.params)
        .then((res) => {
          this.tableLoader = this.searchLoader = false;
          this.makePagination(res.data);
          this.students = res.data.data;
        })
        .catch((err) => {
          this.tableLoader = this.searchLoader = false;
        });
    },
    getHeis() {
      axios
        .get('api/heis')
        .then((response) => {
          this.heis = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPrograms() {
      if (this.$store.state.programs.length) {
        return;
      }

      axios
        .get('api/programs')
        .then((response) => {
          this.$store.state.programs = response.data;
        })
        .catch((error) => {});
    },
  },
  mounted() {
    this.search(this.DEFAULT_URL, {
      searchLoader: { isEnabled: false },
      tableLoader: { isEnabled: true },
    });
    this.getHeis();
    this.getPrograms();
  },
};
</script>

<style scoped>
#masterlist-table tr {
  cursor: pointer !important;
}
</style>
